import React, { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import axios from "axios";
import { API_BASE_URL } from './Api';


function ConfirmBooking() {
    const { room, id, size, randomId } = useParams();
    const [gameData, setGameData] = useState([]);
    const [userBookings, setUserBookings] = useState([]);
    const location = useLocation();
    const { bookingid } = location.state || {};
    const { uid } = bookingid || {};


    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phoneNumber: ''
    });


    const [checkbox1Checked, setCheckbox1Checked] = useState(false);
    const [checkbox2Checked, setCheckbox2Checked] = useState(false);
    const [error, setError] = useState('');
    const [err, setErr] = useState('');
    const [verror, setVerror] = useState('');


    const handleCheckbox1Change = (e) => {
        setCheckbox1Checked(e.target.checked);
        setError('');
    };

    const handleCheckbox2Change = (e) => {
        setCheckbox2Checked(e.target.checked);
        setError('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phoneNumber: value });
    };
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if ((!checkbox1Checked || !checkbox2Checked) || (!checkbox1Checked && !checkbox2Checked)) {
            setError('Please check the box before booking.');
            return;
        }

        try {
            const requestData = {
                formData: formData,
                userBookings: {
                    date: userBookings[0].date,
                    time: userBookings[0].time,
                    room: userBookings[0].room_id,
                    bookingid: userBookings[0].bookingid
                }
            };
            const response = await axios.post(`${API_BASE_URL}/savebooking`, requestData);

            if (response.data.errors) {
                console.log(response.data.errors);
                setVerror(response.data.errors);
            } else {

                const id = userBookings[0].bookingid;
                if (response.data.success) {
                    navigate(`/bookingsuccess/${id}`);
                }
                else {
                    setErr("You cannot book on this Time. Booking already confirmed in other room");
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/getid/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch game data');
                }
                const data = await response.json();
                setGameData(data);
            } catch (error) {
                console.error('Error fetching game data:', error);
            }
        };

        fetchGameData();
    }, []);


    useEffect(() => {
        const fetchBookingsData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/getuserbookeddetails?uid=${uid}`);
                const data = await response.data;
                setUserBookings(data);
            } catch (error) {
                console.error('Error User Bookings data:', error);
            }
        }
        fetchBookingsData();
    }, []);
   

    return (
        <section className="confrim_booking">
            <div className="container">
                <div className="confrim_booking_box_container">
                    <div className="confrim_booking_box">
                        <div className="confrim_booking_top_bar">
                            <div className="row">
                                <div className="col-md-auto">
                                    <h3>Booking <span>Summary</span></h3>
                                </div>
                                <div className="col">
                                    <hr className="heding_decor" />
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="confrim_booking_box_left_column">
                                    <div className="confrimed_game_img">
                                        <img src={`https://admin1.vr-vibe.com/${gameData.image}`} alt="game_image" />
                                    </div>
                                    <div className="confrimed_game_details">
                                        <div className="booking_game_name">
                                            <h2>{gameData.manipulated_game_name}</h2>
                                        </div>
                                        {userBookings.map((booking, index) => (
                                            <div className="booking_date_time">
                                                <p>Date:<span className="booking_date">{booking.date}</span> , Time:<span className="bookin_time">{booking.time}</span></p>
                                            </div>
                                        ))}
                                        <div className="booking_game_guest_size">
                                            <p>{size} Guests</p>
                                        </div>
                                        <div className="booking_game_prize">
                                            <p><strong>Total Prize : $</strong>{size * gameData.cost}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="confrim_booking_box_right_column">
                                    <div className="confrim_booking_form">
                                        <div className="confrim_booking_form_box">
                                            <div className="confrim_booking_form_box_form">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">First Name</label>
                                                                <input type="text" className="form-control" name="fname" placeholder="" value={formData.fname} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">Last Name</label>
                                                                <input type="text" className="form-control" name="lname" placeholder="" value={formData.lname} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">Email address</label>
                                                                <input type="email" className="form-control" name="email" placeholder="" value={formData.email} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">Phone Number</label>
                                                                <PhoneInput
                                                                    placeholder=""
                                                                    international
                                                                    defaultCountry="US"
                                                                    value={formData.phoneNumber}
                                                                    onChange={handlePhoneChange}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="confrim_booking_form_box_form_check_box mt-3 mb-3">
                                                                <input type="checkbox" name="" id="checkbox1" checked={checkbox1Checked} onChange={handleCheckbox1Change} />
                                                                <label htmlFor="">I acknowledge an adult (over 18), or guardian, is required to sign the waiver for any minors participating in the experience.</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="mb-3 confrim_booking_form_box_form_check_box">
                                                                <input type="checkbox" name="" id="checkbox2" checked={checkbox2Checked} onChange={handleCheckbox2Change} />
                                                                <label htmlFor="">I agree to my email being used to receive the newsletters and promotions.</label>
                                                            </div>
                                                        </div>
                                                        {error && <p style={{ color: 'white' }}>{error}</p>}
                                                        {err && <p style={{ color: 'white' }}>{err}</p>}
                                                        {verror && (
                                                            <div style={{ color: 'white' }}>
                                                                {Object.values(verror).map((error, index) => (
                                                                    <p key={index}>{error}</p>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <div className="col-lg-12 mt-3 justify-content-center d-flex">
                                                            <button type="submit">Book Now</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConfirmBooking;