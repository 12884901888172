import React , {useState, useEffect} from "react";
import SuccessImage from '../assets/images/success.png';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from './Api'; 



function BookingSuccess() {

    const [latestData, setLatestData] = useState([]);
    const { id } = useParams();
    
    useEffect(() => {
        const updateBooking = async () => {
            try {
                
                const response = await axios.get(`${API_BASE_URL}/update-booking/${id}`);
                const data = response.data;
                setLatestData(data);
                
            } catch (error) {
                console.error('Error updating booking:', error);
            }
        };
      
        updateBooking();
    }, []);


    return (
        <section className="booking_success_section">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8">
                        <div className="booking_success_section_container">
                            <div className="booking_success_box">
                                <div className="booking_success_img_box">
                                    <img src={SuccessImage} alt="" />
                                </div>
                                <h4>Your Booking no: {latestData.bookingid}</h4>
                                <h1>Your booking has been successfully completed.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BookingSuccess;
