import React, {useState} from "react";
import axios from "axios";
import { API_BASE_URL } from './Api'; 

 

function PrivateEvents() {
    const initialFormData = {
        name: '',
        email: '',
        members: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');  

    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post(`${API_BASE_URL}/receive-email`, formData);
          console.log(response.data);
          setMessage('Event Submitted');
          setFormData(initialFormData);
        } catch (error) {
          console.error('Error sending email:', error);
          setError('Error submiting Form Data :'+ error.message);
        }
      };
    return (
        <section className="private_events_section">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8">
                        <div className="private_events_section_container">
                            <div className="private_events_box">
                                <div className="private_events_box_top_bar">
                                    <div className="row">
                                        <div className="col-md-auto">
                                            <h3>Private<span> Events</span></h3>
                                        </div>
                                        <div className="col">
                                            <hr className="heding_decor" />
                                        </div>
                                    </div>
                                </div>


                                <div className="private_events_box_form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Name</label>
                                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Email Address</label>
                                                    <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Members</label>
                                                    <input type="tel" className="form-control" name="members" value={formData.members} onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Message</label>
                                                    <textarea name="message" id="message" cols="30" rows="10" className="form-control" value={formData.message} onChange={handleChange}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-3 justify-content-center d-flex">
                                                {message && <p style={{ color: 'white' }}>{message}</p>}
                                                {error && <p style={{ color: 'white' }}>{error}</p>}
                                            </div>
                                            <div className="col-lg-12 mt-3 justify-content-center d-flex">
                                                <button type="submit" className="w-25">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivateEvents;