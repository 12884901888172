import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { API_BASE_URL } from './Api'; 


function Rooms() {
    

    const [rooms, setRooms] = useState([]);

    
    const fetchRooms = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/get_rooms_data`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch rooms');
            } else {
                const data = response.data;
                
                setRooms(data);
            }
            
        } catch (error) {
            console.error('Error fetching rooms:', error);
        }
    };

    useEffect(() => {
        fetchRooms(); // Fetch data when the component mounts
    }, []);

console.log(rooms);

    return (
        <section className="select_room">
            <div className="container">
                <div className="select_room_box_container">
                    <div className="select_room_box">
                        <div className="select_room_top_bar">
                            <div className="row">
                                <div className="col-md-auto">
                                    <h3>Select <span>Room</span></h3>
                                </div>
                                <div className="col">
                                    <hr className="heding_decor" />
                                </div>
                            </div>
                        </div>
                        {/* {bookingSuccess && <p className="white">Booking Success!</p>} */}
                        <div className="select_room_cards">
                            <div className="row align-items-center justify-content-center">
                            {rooms.map((room, index) => (
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="select_room_card_item">
                                        <div className="select_room_card_body">
                                            <div className="select_room_card_image">
                                                <div className="select_room_card_image_area">
                                                    <img src={`https://admin1.vr-vibe.com/${room.image}`} alt={room.room} />
                                                </div>
                                            </div>
                                            <div className="select_room_card_content">
                                                <h3>{room.room}</h3>
                                                <div className="select_room_card_btn">
                                                    <Link to={`/${room.id}`}>
                                                        <button>Select Room</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Rooms;