import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import GameEdit from '../assets/images/edit.png';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from './Api'; 


function GuestSize() {
    const { room, id, type } = useParams();
    const [gameData, setGameData] = useState(null);

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/getid/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch game data');
                }
                const data = await response.json();
               
                setGameData(data);
                
            } catch (error) {
                console.error('Error fetching game data:', error);
            }
        };

        fetchGameData();
    }, [id]);

    return (

        <section className="select_your_guest_size">

            <div className="container">
                <div className="select_your_guest_size_container">
                    <div className="select_your_guest_size_box">
                        <div className="row">
                            <div className="col-lg-8">

                                <div className="select_your_guest_size_top_bar">
                                    <div className="row">
                                        <div className="col-md-auto">
                                            <h3>Select <span>Your Guest Size</span></h3>
                                        </div>
                                        <div className="col">
                                            <hr className="heding_decor" />
                                        </div>
                                    </div>
                                </div>

                                {gameData && (
                                    <div className="select_your_guest_size_group">
                                        <div className="row">
                                            {Array.from({ length: gameData.theme_size -1}, (_, index) => (
                                                <div key={index} className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center select_your_guest_size_group_col">
                                                    <div className="select_your_guest_size_group_btn text-center">
                                                        <Link to={`/datetime/${gameData.room}/${gameData.game_name}/${index + 2}/${type}`}>
                                                            <button>
                                                                <h3>{index + 2}</h3>
                                                                <span>Guests</span>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                               
                                <div className="guest_size_list">
                                    <ul>
                                        <li>Looking for a larger party?</li>
                                        <li>Guests must be 1.2m (48″) or taller to play</li>
                                        <li>Glasses cannot be worn. We recommend that you wear contacts as glasses may get scratched. Alternatively, we have limited sets of corrective lenses we can place in our headsets that range from 200 to 700 degrees if you need them.</li>
                                        <li>We can arrange a public session when your team does not have enough players.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="select_your_game_box_right_col">
                                    <div className="select_your_game_box_side_bar">

                                        <div className="select_your_game_side_bar_top_bar">
                                            <div className="row">
                                                <div className="col-md-auto">
                                                    <h3>Your <span>Booking</span></h3>
                                                </div>
                                                <div className="col">
                                                    <hr className="heding_decor" />
                                                </div>
                                            </div>
                                        </div>
                                        {gameData && (
                                            <div className="game_box_side_bar_game_box">
                                                <div className="game_box_side_bar_game_box_heading">
                                                    <Link to="/rooms">
                                                        <h5>{gameData.manipulated_room}</h5> <img src={GameEdit} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                        {gameData && (
                                            <div className="game_box_side_bar_game_box">
                                                <div className="game_box_side_bar_game_box_heading">
                                                        <Link to={`/${gameData.room}`}>
                                                            <h5>{gameData.manipulated_game_name}</h5>
                                                            <img src={GameEdit} alt="" />
                                                        </Link>
                                                    
                                                </div>
                                            </div>
                                        )}

                                        <p>Pricing may vary on non-peak days and/or times.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GuestSize;