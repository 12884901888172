import React from "react";
import { Link } from 'react-router-dom';
import FooterLogo from '../assets/images/logo.png'

function Footer() {
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="footer_top_bar">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="footer_top_bar_logo">
                                    <Link to="/">
                                        <img src={FooterLogo} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom_bar">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="footer_bottom_bar_left">
                                    <p>© 2024 <Link to="/">VR VIBE</Link> | All Rights Reserved.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="footer_bottom_bar_right">
                                    <p>Powered by <Link to="https://www.honeysoftsolutions.in/" target="_blank">Honey Soft Solutions</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;