import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';


const GameCard = ({ games }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    // Initialize slick carousel after component mounts
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: window.innerWidth <= 768 ? 1 : window.innerWidth <= 990 ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    fade: false,
    cssEase: 'linear',
    draggable: true
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {games.map((slide, index) => (
        <div key={slide.id} className="game_card_wrapper" style={{ marginRight: '20px' }}>
          <div className="game_card">
            <Link to={`/guestsize/${slide.room}/${slide.gameid}/${slide.type}`}>
              <div className="game_card_img">
                <img src={`https://admin1.vr-vibe.com/${slide.image}`} alt={slide.title} />
              </div>
              <div className="game_card_content">
                <h2>{slide.game_name}</h2>
                <p>{slide.description.length > 100 ? slide.description.substring(0, 100) + "..." : slide.description}</p>
                <Link to={`/guestsize/${slide.room}/${slide.gameid}/${slide.type}`}>
                  <button>Book Now</button>
                </Link>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default GameCard;
