import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import VideoModal from './VideoModal';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from './Api'; 


function GameDetail() {
    
    const [gameData, setGameData] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);

    const { room, id } = useParams();
    
    const [isModalOpen, setIsModalOpen] = useState(false);
   

    const handleWatchTrailer = (videoUrl) => {
        setVideoUrls(videoUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/getid/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch game data');
                }
                const data = await response.json();
                
                setGameData(data);
                
            } catch (error) {
                console.error('Error fetching game data:', error);
            }
        };

        fetchGameData();
    }, [id]);

  

    if (!gameData || !gameData.images) {
        return null; 
      }



    return (
        <section className="game_detail">
            {gameData && (
                <div className="game_detail_intro_section">
                    <div className="game_detail_intro_content_box">
                        <div className="container">
                            <div className="row d-flex align-items-center">

                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="game_detail_intro_image">
                                        <img src={`https://admin1.vr-vibe.com/${gameData.image}`} alt="image1" />
                                        
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="game_detail_intro_content">
                                        <h2>{gameData.manipulated_game_name}</h2>
                                        {/* <h6></h6> */}
                                        <p>{gameData.description}</p>
                                        <Link to={`/guestsize/${gameData.original_room}/${gameData.original_game_name}/${gameData.type}`}>
                                            <button>
                                                Book Now
                                            </button>
                                        </Link>
                                        <Link>

                                            <button onClick={() => handleWatchTrailer(gameData.trailer)}>Watch Trailer</button>
                                            
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="game_detail_score_table_box">
                <div className="container">
                    <div className="game_detail_images">
                        <div className="container">
                            <div className="row">
                            {gameData.images.map((imageUrl, index) => (
                                <div className="col-lg-6">
                                    <div className="game_detail_image_item">
                                        
                                            <img key={`${imageUrl}-${index}`} src={`https://admin1.vr-vibe.com/${imageUrl}`} alt='images' />
                                        
                                    </div>
                                </div>
                            ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VideoModal isOpen={isModalOpen} onClose={closeModal} videoUrls={videoUrls} />
        </section>
    )
}

export default GameDetail;