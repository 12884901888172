import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const ParallaxHeroCarousel = ({ slides }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    // Initialize slick carousel after component mounts
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    fade: true,
    cssEase: 'linear',
    draggable: true
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {slides.map((slide, index) => (
        <div key={slide.id} className="parallax-slide">
          <div className="parallax-background" style={{ backgroundImage: `url(${slide.image})` }}></div>
          <div className="parallax-content">
            <Link to={`${slide.slug}`}>
                  <button>Book an Experience</button>
                </Link>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ParallaxHeroCarousel;
