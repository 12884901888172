import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from "./pages/Home";
import VREscapeRooms from "./pages/VREscapeRooms";
import Rooms from "./pages/Rooms";
import GameDetail from "./pages/GameDetail";
import GuestSize from "./pages/GuestSize";
import { DateTime } from './pages/DateTime';
import PrivateEvents from "./pages/PrivateEvents";
import Faq from "./pages/Faq";
import BookingSuccess from './pages/BookingSuccess';
import ConfirmBooking from './pages/ConfirmBooking';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/:room" element={<VREscapeRooms />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/gamedetail/:room/:id" element={<GameDetail />} />
        <Route path="/guestsize/:room/:id/:type" element={<GuestSize />} />
        <Route path="/datetime/:room/:id/:size/:type" element={<DateTime />} />
        <Route path="/privateevents" element={<PrivateEvents />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/bookingsuccess/:id" element={<BookingSuccess />} />
        <Route path="/confirmbooking/:room/:id/:size" element={<ConfirmBooking />} />
      </Routes>
      <Footer />
      
    </Router>
  );
}

export default App;
