// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background: #170947 !important;
    font-family: "Poppins", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Chakra Petch", sans-serif !important;
}

.react-calendar__tile:disabled {
    background-color: #00fffc8c !important;
    color: #ababab;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;IACI,8BAA8B;IAC9B,6CAA6C;AACjD;;AAEA;;;;;;IAMI,kDAAkD;AACtD;;AAEA;IACI,sCAAsC;IACtC,cAAc;AAClB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap\");\n\nbody {\n    background: #170947 !important;\n    font-family: \"Poppins\", sans-serif !important;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n    font-family: \"Chakra Petch\", sans-serif !important;\n}\n\n.react-calendar__tile:disabled {\n    background-color: #00fffc8c !important;\n    color: #ababab;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
