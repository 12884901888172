import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import GameEdit from '../assets/images/edit.png';
import { useParams } from 'react-router-dom';
import axios from "axios";
// import { format } from 'date-fns';
import { API_BASE_URL } from './Api';
// import moment from 'moment-timezone';


export function DateTime() {
    const { room, id, size, type } = useParams();
    const [gameData, setGameData] = useState([]);
    const navigate = useNavigate();
    const [userBookings, setUserBookings] = useState([]);
    const [userId, setUserId] = useState([]);
    const [error, setError] = useState('');

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log("User's timezone:", userTimezone);

    const getCurrentDateTimezone = () => {
        const currentDate = new Date();
        // console.log("timezone:", userTimezone);
        const options = {
            timeZone: userTimezone,
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        };
        
        return currentDate.toLocaleString("en-US", options);
    };


    const [formData, setFormData] = useState({
        calendarValue: getCurrentDateTimezone(),
        selectedTime: ''
    });

    const { calendarValue, selectedTime } = formData;

    const fetchBookingsData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/getuserdetails?calendarValue=${formData.calendarValue}&type=${type}`);
            const data = await response.data;

            setUserBookings(data);
        } catch (error) {
            console.error('Error User Bookings data:', error);
        }
    };

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/getid/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch game data');
                }
                const data = await response.json();

                setGameData(data);

            } catch (error) {
                console.error('Error fetching game data:', error);
            }
        };

        fetchGameData();
    }, []);

    const postData = async () => {
        try {
            console.log(id, size, room, type, userTimezone);
            const response = await axios.post(`${API_BASE_URL}/savesizeidroom`, {
                id,
                size,
                room,
                type,
                userTimezone
            });

            const data = response.data;
            setUserId(data);
        } catch (error) {
            console.error('Error fetching saved data:', error);
        }
    };

    const isFirstRender = useRef(true);

    useEffect(() => {
        console.log(userTimezone);

        if (isFirstRender.current && userTimezone) {
            isFirstRender.current = false;
            postData();
        }
    }, []);

    useEffect(() => {
        fetchBookingsData();
        console.log(calendarValue);
    }, [calendarValue]);

    const handleCalendarChange = (date) => {
        // const dateString = date.toString().slice(4, 15);
        /*
         console.log(date); //Fri Apr 26 2024 00:00:00 GMT+0530 (India Standard Time)

         const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

         console.log(utcDate); //Fri Apr 26 2024 05:30:00 GMT+0530 (India Standard Time)

         utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset());

         console.log(utcDate); //Fri Apr 26 2024 00:00:00 GMT+0530 (India Standard Time)
         */

         /*
        const utcTimestamp = date.getTime();

        // console.log(utcTimestamp); //1714041758020
   
    const asiaCalcuttaDate = new Date(utcTimestamp);
    const americaTorontoDate = new Date(utcTimestamp);
    // console.log(americaTorontoDate) //Fri Apr 26 2024 00:00:00 GMT+0530 (India Standard Time)

    console.log("Asia/Calcutta Date:", asiaCalcuttaDate.toLocaleDateString("en-IN")); //Asia/Calcutta Date: 25/4/2024
    console.log("America/Toronto Date:", americaTorontoDate.toLocaleDateString("en-US", {timeZone: "America/Toronto"})); //America/Toronto Date: 4/25/2024
    const newdate = asiaCalcuttaDate.toLocaleDateString("en-IN");
    console.log(newdate); */

        const utcTimestamp = date.getTime();
        console.log(utcTimestamp); //1714069800000

        const userLocale = navigator.language || navigator.userLanguage;
        console.log(userLocale); //en-US
    
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(userTimezone); //Asia/Calcutta
    
        const userDate = new Date(utcTimestamp);
        console.log(userDate); //Fri Apr 26 2024 00:00:00 GMT+0530 (India Standard Time)
        
        const newdate= userDate.toLocaleDateString(userLocale, {timeZone: userTimezone});
        console.log(newdate); //4/26/2024
        
        setFormData({
            ...formData,
            calendarValue: newdate
        });
    };

    const handleTimeSelect = (time) => {
        setFormData({
            ...formData,
            selectedTime: time,
        });
        setError('');
    };

    const uid = userId.bookingid;

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!formData.selectedTime) {
                setError('Please select the Time and submit form.');
                return; // Stop further execution
            }
            const requestData = {
                ...formData, // Included calendarValue and selectedTime in the formData
                uid: uid,
            };
            console.log("timezone logging in submit method:", userTimezone);
            const response = await fetch(`${API_BASE_URL}/savedate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            navigate(`/confirmbooking/${room}/${id}/${size}`, { state: { bookingid: { uid } } });

            if (!response.ok) {
                throw new Error('Failed to submit form');
            }

            const responseData = await response.json();

            navigate(`/confirmbooking/${room}/${id}/${size}`, { state: { bookingid: { uid } } });
            // Handle response as needed
        } catch (error) {
            console.error('Error submitting form data :-', error);
        }
    };

    const cancelSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.get(`${API_BASE_URL}/remove-records`);

            navigate(`/rooms`);

        } catch (error) {
            console.error('Error removing records :', error);
        }
    };


    return (
        <section className="select_date_time">
            <div className="container">
                <div className="select_date_time_container">
                    <div className="select_date_time_box">

                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12">

                                    <div className="select_date_time_top_bar">
                                        <div className="row">
                                            <div className="col-md-auto">
                                                <h3>Select <span>Date</span></h3>
                                            </div>
                                            <div className="col">
                                                <hr className="heding_decor" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="select_date_box">
                                        <Calendar
                                            minDate={new Date()}
                                            onChange={handleCalendarChange}
                                            value={new Date(formData.calendarValue)} />
                                    </div>

                                    <div className="select_time_box">
                                        <div className="select_time_box_header">
                                            <div className="row">
                                                <div className="col-md-auto">
                                                    <h3>Select <span> Time Slot</span></h3>
                                                </div>
                                                <div className="col">
                                                    <hr className="heding_decor" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row d-flex justify-content-center">

                                            {userBookings && userBookings.length > 0 && userBookings.map((timeSlot, index) => (
                                                <div key={index} className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center select_time_box_col">
                                                    <div className="select_time_box_btn">
                                                        <button type="button" onClick={() => handleTimeSelect(timeSlot.time)}>
                                                            <h4>{timeSlot.time}</h4> {/* Accessing 'time' property */}
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <div className="select_your_game_box_right_col">
                                        <div className="select_your_game_box_side_bar">

                                            <div className="select_your_game_side_bar_top_bar">
                                                <div className="row">
                                                    <div className="col-md-auto">
                                                        <h3>Your <span>Booking</span></h3>
                                                    </div>
                                                    <div className="col">
                                                        <hr className="heding_decor" />
                                                    </div>
                                                </div>
                                            </div>
                                            {gameData && (
                                                <div className="game_box_side_bar_game_box">
                                                    <div className="game_box_side_bar_game_box_heading">
                                                        <Link to="/rooms">
                                                            <h5>{gameData.manipulated_room}</h5> <img src={GameEdit} alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                            {gameData && (
                                                <div className="game_box_side_bar_game_box">
                                                    <div className="game_box_side_bar_game_box_heading">

                                                        <Link to={`/${room}`}>
                                                            <h5>{gameData.manipulated_game_name}</h5>
                                                            <img src={GameEdit} alt="" />
                                                        </Link>

                                                    </div>
                                                </div>
                                            )}
                                            <div className="game_box_side_bar_guest_box">
                                                <div className="game_box_side_bar_guest_box_heading">
                                                    <Link to={`/guestsize/${room}/${id}/${type}`}>
                                                        <h5>{size}</h5> <img src={GameEdit} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="game_box_side_bar_guest_box">
                                                <div className="game_box_side_bar_guest_box_heading">
                                                    <Link to={`/datetime/${room}/${id}/${size}/${type}`}>
                                                        <h5>{formData.calendarValue.toString()}</h5> <img src={GameEdit} alt="" />
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="game_box_side_bar_date_time_box">
                                                {formData.selectedTime && (
                                                    <div className="game_box_side_bar_date_time_box_heading">
                                                        <Link to={`/datetime/${room}/${id}/${size}/${type}`}>
                                                            <h5>{formData.selectedTime}</h5> <img src={GameEdit} alt="" />
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                            {error && <p style={{ color: 'white' }}>{error}</p>}
                                            <div className="date-time-action-btn">
                                                <button type="submit">Confirm</button>
                                                <button type="button" onClick={cancelSubmit}>Cancel</button>
                                            </div>

                                            <p>Pricing may vary on non-peak days and/or times.</p>

                                        </div>
                                    </div>
                                </div>
                            </div></form>
                    </div>
                </div>
            </div>
        </section>
    );

}
