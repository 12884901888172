import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import VideoModal from './VideoModal';
import { API_BASE_URL } from './Api'; 


function VREscapeRooms() {

    const [games, setGames] = useState([]);
    const { room } = useParams();
    const [videoUrls, setVideoUrls] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);   
    
    const handleWatchTrailer = (videoUrl) => {
        setVideoUrls(videoUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const fetchGames = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/get_vrrooms_data`, {
                params: {
                    room: room // room received from rooms page
                }
            });
            if (response.status !== 200) {
                throw new Error('Failed to fetch games');
            }
            const data = response.data;

            setGames(data);
            
        } catch (error) {
            console.error('Error fetching games:', error);
        }
    };

    useEffect(() => {
        fetchGames(); // Fetch data when the component mounts
    }, [room]);

    return (
        <section className="select_your_game">
            <div className="container">
                <div className="select_your_game_box_container">
                    <div className="select_your_game_box">
                        <div className="select_your_game_top_bar">
                            <div className="row">
                            {games && games.length > 0 &&(
                                <div className="col-md-auto">
                                    <h3>{games[0].original_room}</h3>
                                </div>
                            )}
                                <div className="col">
                                    <hr className="heding_decor" />
                                </div>
                            </div>
                        </div>
                        <div className="select_your_game_box_row">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="select_your_game_box_left_col">
                                        <div className="row">
                                            {games.map((game, index) => (
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="select_your_game_card">

                                                        <div className="select_your_game_card_content">
                                                       
                                                                <div className="select_your_game_card_label">
                                                                <button onClick={() => handleWatchTrailer(game.trailer)}><img src="https://admin1.vr-vibe.com/images/youtube.png" alt="img" /></button>
                                                                </div>
                                                                <div className="select_your_game_card_label1">
                                                                {game.type === 1 && (
                                                                    <img src="https://admin1.vr-vibe.com/images/ribben.png" alt="img" />
                                                                )}
                                                                </div>
                                                            

                                                            <div className="select_your_game_card_info">
                                                                <Link to={`/guestsize/${game.room}/${game.original_game_name}/${game.type}`}>
                                                                    <h4>{game.game_name}</h4>
                                                                    <p><img src="https://admin1.vr-vibe.com/images/team.png" alt="img" /><span className="select_your_game_batch">{game.theme_size}</span></p>
                                                                    <p className='price_field'><span className="price">${game.cost}</span><span className="guest">/GUEST</span></p>
                                                                </Link>
                                                                <div className="select_your_game_detail_btn">
                                                                    <Link to={`/gamedetail/${game.room}/${game.original_game_name}`}>
                                                                        <button>Learn More</button>
                                                                    </Link>
                                                                    <Link to={`/guestsize/${game.room}/${game.original_game_name}/${game.type}`}>
                                                                    <button>Book Now</button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img src={`https://admin1.vr-vibe.com/${game.image}`} alt="image1" />
                                                    </div>
                                                </div>
                                            ))}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VideoModal isOpen={isModalOpen} onClose={closeModal} videoUrls={videoUrls} />
        </section>
    )
}

export default VREscapeRooms;
