import Container from 'react-bootstrap/Container';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Logo from '../assets/images/logo.png'
// import axios from 'axios';

function Header() {

    const [expanded, setExpanded] = useState(false);

    const handleNavbarToggle = () => {
        setExpanded(!expanded);
    };

//     const [rId, setRId] = useState(null);

//     useEffect(() => {
//         // Function to fetch random user ID from Laravel backend
//         const fetchRandomId = async () => {
//             try {
//                 const response = await axios.get('http://localhost:8000/api/generate-random-id');
//                 setRId(response.data.randomId);
//             } catch (error) {
//                 console.error('Error fetching random ID:', error);
//             }
//         };

//         fetchRandomId();
//     }, []);
// console.log(rId);

    return (
        <Navbar collapseOnSelect expand="lg" expanded={expanded}>
            <Container fluid>
                <Navbar.Brand as={Link} to="/">
                    <img src={Logo} alt={Logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleNavbarToggle}/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav onClick={expanded ? handleNavbarToggle : null}>
                        <Nav.Link as={Link} to="/" className="nav-link">Home</Nav.Link>
                        <Nav.Link as={Link} to={`/1`} className="nav-link">VR Escape Rooms</Nav.Link>
                        <Nav.Link as={Link} to={`/2`} className="nav-link">VR Shooting Arena</Nav.Link>
                        <Nav.Link as={Link} to="/rooms" className="nav-link">Book Now</Nav.Link>
                        <Nav.Link as={Link} to="/privateevents" className="nav-link">Private Events</Nav.Link>
                        <Nav.Link as={Link} to="/faq" className="nav-link">Faq</Nav.Link>
                        <Nav.Link as={Link} to="/login" className="nav-link">Login</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
