import React from 'react';
import Modal from 'react-modal';


const VideoModal = ({ isOpen, onClose, videoUrls }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Video Modal"
    >
      <button onClick={onClose}>X</button>
      <div className="video-container">
        <iframe
          title="YouTube Video"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoUrls}`}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </Modal>
  );
};

export default VideoModal;
