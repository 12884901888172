import React from 'react';

const HomeVideoSection = ({ videoUrl }) => {
  return (
    <div className="video-section">
      <video autoPlay loop muted>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default HomeVideoSection;
