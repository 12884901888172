import React, {useState, useEffect} from "react";
import ParallaxHeroCarousel from './ParallaxHeroCarousel';
import HomeVideoSection from './HomeVideoSection';
import myVideo from '../assets/videos/vedio.mp4';
import { Link } from 'react-router-dom';
import GameCard from './GameCard';
import axios from "axios";
import darkWarriorImage from '../assets/images/dark_warrior.png';
import sliderImage1 from '../assets/images/slider-1.jpg';
import sliderImage2 from '../assets/images/slider-2.jpg';
import sliderImage3 from '../assets/images/slider-3.jpg';
import { API_BASE_URL } from './Api'; 

function Home() {

    const slides = [
        {
            id: 1,
            image: sliderImage1,
            slug: 'rooms'
        },
        {
            id: 2,
            image: sliderImage2,
            slug: 'rooms'
        },
        {
            id: 3,
            image: sliderImage3,
            slug: 'rooms'
        }
        
    ];
    const [games, setGames] = useState([]);


    const fetchAllGames = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/get_allgames_data`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch games');
            }
            const data = response.data;
            
            setGames(data);
           
        } catch (error) {
            console.error('Error fetching games:', error);
        }
    };

    useEffect(() => {
        fetchAllGames(); // Fetch data when the component mounts
    }, []);


    return (
        <section className="home-box">
            <div className="image_carousel_section">
                <div className="image_carousel_section_top_bg"></div>
                <div className="app">
                    <ParallaxHeroCarousel slides={slides} />
                </div>
                <div className="image_carousel_section_bottom_bg"></div>
            </div>

            <div className="video_section">
                <div className="video_section_top_bg"></div>
                <HomeVideoSection videoUrl={myVideo} />
                <div className="video_section_bottom_bg"></div>
            </div>

            <div className="about_section">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 about_col_order_2">
                            <div className="about_img">
                                <img src={darkWarriorImage} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 about_col_order_1">
                            <div className="about_content">
                                <h6>Transcend Reality</h6>
                                <h2>Your Body, Your Avatar, Your Adventure.</h2>
                                <p>VR Vibe offers an immersive VR adventure designed for groups of up to 6, allowing you to visually and physically engage with everyone as if you were in the real world. Drawing inspiration from Star Trek's Holodeck, our exclusive environments transport you into the heart of games or movies, crafted by veterans from EA, Sony, and Ubisoft. Endorsed by Justin Timberlake, Katy Perry, Kevin Durant, and Will Smith, our experiences redefine possibility. With Hollywood-grade motion capture cameras, 3D body trackers, specialized hardware, and haptic suits, you undergo a transformative journey unparalleled elsewhere.</p>
                                <p>As you step into our worlds, you become the protagonist, immersed in action and adventure. Together with others, our original scenarios, from space odysseys to zombie showdowns, forge unforgettable bonds. Event and party bookings are now available, so embark on your VR journey with us today.</p>
                                <Link to="/rooms">
                                    <button>Book an Experience</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* About Content Box Two Starts */}
            <div className="about_section_two">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about_content">
                                <h6>Transcend Reality</h6>
                                <h2>Your Body, Your Avatar, Your Adventure.</h2>
                                <p>VR Vibe offers an immersive VR adventure designed for groups of up to 6, allowing you to visually and physically engage with everyone as if you were in the real world. Drawing inspiration from Star Trek's Holodeck, our exclusive environments transport you into the heart of games or movies, crafted by veterans from EA, Sony, and Ubisoft. Endorsed by Justin Timberlake, Katy Perry, Kevin Durant, and Will Smith, our experiences redefine possibility. With Hollywood-grade motion capture cameras, 3D body trackers, specialized hardware, and haptic suits, you undergo a transformative journey unparalleled elsewhere.</p>
                                <p>As you step into our worlds, you become the protagonist, immersed in action and adventure. Together with others, our original scenarios, from space odysseys to zombie showdowns, forge unforgettable bonds. Event and party bookings are now available, so embark on your VR journey with us today.</p>
                                <Link to="/rooms">
                                    <button>Book an Experience</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="games_carousel_section">
                <div className="container-fluid">
                    <GameCard games={games} />
                </div>
            </div>

        </section>
    )
}

export default Home;